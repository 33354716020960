/** ***
 * RSA加密解密工具类
 * **/
import { JSEncrypt } from 'jsencrypt' // 导入
// 公钥和私钥需前后端一致
// 公钥
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRJvD21lpX0B8LHvu0aiHNhEf3lRqkGLnxXrOAqGJcMl1J3XKgeceusLqwc2wyVSiOCWjixI+I+dvJB27X5naDlifnq8KyfVe5ei0KgYXkxYJIxCQ254/ZB5Rd4Mzt+aYD1NxO+CEBanwtkk6fBu6rueqpO4U5qVXzk39TP4IVBQIDAQAB'
// 私钥
const privateKey = '获取的私钥'

// 加密
export function encrypt(data) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(data) // 对需要加密的数据进行加密
}

// 解密
export function decrypt(data) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decrypt(data)
}