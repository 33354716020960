import request from '@/../utils/request'
const api_name = '/iotechserver/UserMandateController'

export default {
    //查询是否开启授权管理
    getAuthorize() {
        return request({
            url: `${api_name}/getAuthorize`,
            header: "application/json;charset=UTF-8",
            method: 'get',
        })
    },
    // 判断是否授权
    getRobotCode(username, robotCode) {
        return request({
            url: `${api_name}/getRobotCode/${username}/${robotCode}`,
            header: "application/json;charset=UTF-8",
            method: 'get',

        })
    },
    // 保存机器码
    setRobotCode(username) {
        return request({
            url: `${api_name}/setRobotCode/${username}`,
            header: "application/json;charset=UTF-8",
            method: 'get',

        })
    },

    // 未授权列表
    getIsMandate(current, limit) {
        return request({
            url: `${api_name}/getIsMandate/${current}/${limit}`,
            method: 'get',
        })
    },

    // 管理员授权
    updateIsMandate(userMandateVo) {
        return request({
            url: `${api_name}/updateIsMandate`,
            header: "application/json;charset=UTF-8",
            method: 'post',
            data: {
                robotCode: userMandateVo.robotCode,
                username: userMandateVo.username,
            },

        })
    },
    // 管理员授权
    allIsMandate() {
        return request({
            url: `${api_name}/allIsMandate`,
            header: "application/json;charset=UTF-8",
            method: 'post',
        })
    },

    //删除 
    delMandate(id) {
        return request({
            url: `${api_name}/delMandate/${id}`,
            header: "application/json;charset=UTF-8",
            method: 'post',

        })
    },
    //开启授权
    openMandate(value) {
        return request({
            url: `${api_name}/openMandate/${value}`,
            header: "application/json;charset=UTF-8",
            method: 'post',

        })
    },



}