<template>
	<el-container>
		<el-main class="back-img">
			<div class="login-logo">
				<!-- <div>智慧能源计量管理监控平台</div> -->
				<div>消防物联网监控平台</div>
				<!-- <div>秦港九公司<br>&nbsp;&nbsp;用水管理监控平台</div> -->
				<!-- <div>黔安云+ </div>
        <div style="margin-left:150px">综合安全管理平台</div> -->
				<!-- <el-image :src="require('@/assets/logo1.png')"> </el-image> -->
			</div>
			<div class="login-form">
				<div class="login_title">欢迎登录</div>
				<el-form class="mtb20" :model="login_form" :rules="rules" status-icon ref="login_form">
					<el-form-item prop="userName">
						<el-input v-model="login_form.userName" prefix-icon="el-icon-user" size="medium"
							placeholder="请输入用户名" clearable>
						</el-input>
					</el-form-item>
					<el-form-item prop="userPsw">
						<el-input v-model="login_form.userPsw" prefix-icon="el-icon-lock" size="medium"
							placeholder="请输入密码" clearable show-password>
						</el-input>
					</el-form-item>
					<div style="display: flex;justify-content: center;align-items: center;">
						<el-input v-model="formLogin.code" placeholder="请输入验证码" clearable prefix-icon="el-icon-key"
							size="medium" style="width: 48%;">
						</el-input>
						<div class="login-code" width="100%" @click="refreshCode" style="margin-left: 5%;">
							<!--验证码组件-->
							<yanzhengma :identifyCode="identifyCode"></yanzhengma>
						</div>
					</div>
					<el-form-item>
						<div class="login-box">
							<a @click="loginIn('login_form')">
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								登录
							</a>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</el-main>
	</el-container>
</template>

<script>
	import yanzhengma from '@/components/yanzhengma/yanzhengma'
	import BaseData from '@/assets/config/BaseData'

	import userApi from "@/api/manageApi/User.js";
	import cookie from "js-cookie";
	import dataStorage from '@/../utils/dataStorage.js'
	import userMandate from '@/api/manageApi/UserMandate'
	import {
		encrypt
	} from '../../utils/jsencrypt'
	// import this.$cryptoJs from 'crypto-js' //引用AES源码js

	export default {
		name: "Login",
		components: {
			yanzhengma
		},

		data() {
			return {
				formLogin: {
					code: "", //验证码输入框
				},
				identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
				identifyCode: "", //验证码图片内容
				password: "",
				pwd: "", //密码中转
				loading: false,
				login_form: {
					userName: "",
					userPsw: "",
				},
				rules: {
					userName: [{
							required: true,
							message: "请输入用户名",
							trigger: "blur"
						},
						{
							min: 3,
							max: 11,
							message: "长度在 3 到 11 个字符",
							trigger: "blur",
						},
					],
					userPsw: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							min: 3,
							max: 16,
							message: "长度在 3 到 16 个字符",
							trigger: "blur",
						},
					],
				},
			};
		},
		created() {

			// 按回车键登录
			var _self = this;
			document.onkeydown = function(e) {
				var key = window.event.keyCode;
				if (key == 13) {
					_self.loginIn("login_form");
				}
			};
		},
		mounted() {
			// 初始化验证码
			this.identifyCode = "";
			//参数：（1）随机串内容。（2）验证码显示位数
			this.makeCode(this.identifyCodes, 4);
		},
		computed: {

		},
		methods: {
			// 重置验证码
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
			},
			//获取验证码的值
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					//通过循环获取字符串内随机几位
					this.identifyCode +=
						this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
				}
			},
			//随机数字：用于当角标拿字符串的值
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min);
			},
			//登录
			aaaH() {
				console.log(111);
			},
			async loginIn(login_form) {
				console.log("验证码:", this.identifyCode);
				console.log("用户输入的验证码:", this.formLogin.code);
				console.log('是否验证通过:', this.identifyCode == this.formLogin.code);
				if (this.identifyCode == this.formLogin.code) {
					this["aaa" + "H"]()
					const valid = await this.$refs[login_form].validate()
					if (valid) {
						if (BaseData.serverIP == "82.156.18.109") {
							this.pwd = this.login_form.userPsw
						} else {
							this.pwd = encrypt(this.login_form.userPsw)
						}
						// 获取token
						const res = await userApi["login"](this.login_form.userName, this.pwd)
						if (res.data.code == 20000) {
							if (this.login_form.userName === "admin") {
								// this.$router.push("/Changepasd");
								const rep = await userApi.periodValidityMax(this.login_form.userName)
								Promise.reject(rep)
								if (rep.data.message === "请修改密码") {
									return this.$router.push("/Changepasd")

								}
								if (rep.data.message === "请设置新密码") {
									return this.$router.push("/Changepasd");
								}
								if (rep.data.message === "密码即将过期，请更改密码") {
									this.$router.push("/select");
									this.$message({
										message: "登陆成功",
										type: "success",
										duration: 1500,
									});
									this.$alert('<strong>密码即将过期，请更改密码</strong>', '提示', {
										dangerouslyUseHTMLString: true
									});
									return
								}
								this.$router.push("/select");
								this.$message({
									message: "登陆成功",
									type: "success",
									duration: 1500,
								});
								return
							}
							if (dataStorage.getBaseData("robot_code-" + this.login_form.userName)) {
								return this.isLogin()
							}
							//生成code接口
							const response = await userMandate.setRobotCode(this.login_form.userName)
							//存的
							console.log(response);
							dataStorage.setBaseData("robot_code-" + this.login_form.userName, response.data.data
								.robot_code)
							this.isLogin()
							return
						}
						this.$message.error("登录失败");
						return false;
					}
				} else if (this.formLogin.code == "") {
					this.$message.warning("验证码不能为空");
				} else {
					this.$message.warning("验证码错误，请重新输入");
				}
			},

			async isLogin() {
				let a = dataStorage.getBaseData("robot_code-" + this.login_form.userName)
				const resp = await userMandate.getRobotCode(this.login_form.userName, a)

				//去调接口
				//传入用户名、机器码查询 是否有授权 如果有进行跳转、无则通知admin
				if (resp.data.message === "已授权") {
					// 判断是否第一次登录
					//如果授权了 调接口判断是否第一次登录等 如果第一次登录跳转修改密码界面，非第一次登录跳转正常界面
					const rep = await userApi.periodValidityMax(this.login_form.userName)

					if (rep.data.message === "请修改密码") {
						this.$router.push("/select");
						this.$message({
							message: "登陆成功",
							type: "success",
							duration: 1500,
						});
						return
					}
					if (rep.data.message === "请设置新密码") {
						// this.$router.push("/Changepasd");
						this.$router.push("/select");
						this.$message({
							message: "登陆成功",
							type: "success",
							duration: 1500,
						});
						return
					}
					if (rep.data.message === "密码即将过期，请更改密码") {
						this.$router.push("/select");
						this.$message({
							message: "登陆成功",
							type: "success",
							duration: 1500,
						});
						this.$alert('<strong>密码即将过期，请更改密码</strong>', '提示', {
							dangerouslyUseHTMLString: true
						});
						return
					}
					this.$router.push("/select");
					this.$message({
						message: "登陆成功",
						type: "success",
						duration: 1500,
					});
					return
				}
				if (resp.data.message === "管理员已拒绝") {
					// alert("123")
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.login-logo {
		font-size: 10vh;
		font-weight: 700;
		color: #fff;
		text-shadow: 5px 5px 5 px #000;
		font-family: "宋体";
		position: absolute;
		top: 20%;
		left: 5%;
	}

	.login-logo div {
		line-height: 15vh;
		text-shadow: 5px 5px 5px #000;
	}

	/deep/ .el-image {
		transform: scale(1, 1);
		cursor: pointer;
	}

	.el-container {
		height: 100vh;
	}

	/deep/ .login-form {
		width: 310px;
		height: 350px;
		font-family: "Times New Roman", Times, serif;
		position: absolute;
		top: 50%;
		left: 72%;
		transform: translate(-50%, -50%);
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
	}

	.el-form-item {
		margin-bottom: 30px;
	}

	/deep/ .el-form-item__label {
		color: #fff;
	}

	/deep/ .login_title {
		text-align: center;
		color: #fff;
		font-weight: 500;
		font-size: 30px;
		margin: 50px 0 10px 0;
		cursor: default;
	}

	.mtb20 {
		display: inline-block;
		margin-top: 22px;
		position: relative;
		left: 50%;
		transform: translate(-50%);
	}

	/deep/ .el-form-item__content {
		text-align: center;
	}

	.el-button {
		width: 100%;
	}

	/deep/.el-input {
		width: 80%;
	}
</style>